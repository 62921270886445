<template>
  <div>
    <!-- 关于我们 -->
    <!-- <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.aboutName" clearable placeholder="请输入关于我们名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.aboutManager" clearable placeholder="请输入关于我们负责人" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form> -->
    
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button v-if="OA.includes('jcxx:gywm:add')" type="success" size="small" icon="el-icon-plus" @click="handleForm(false)">添加</el-button>
      </div>
      <div v-if="aboutTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table
        v-if="aboutTotal>0"
        :data="aboutList"
        :total="aboutTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh"
      > 
        <el-table-column sortable="custom" prop="website" show-overflow-tooltip label="官网" width="250"></el-table-column>
        <el-table-column sortable="custom" prop="cooperation" show-overflow-tooltip label="运营合作"></el-table-column>
        <el-table-column sortable="custom" prop="email" show-overflow-tooltip label="邮箱"></el-table-column>
        <el-table-column sortable="custom" prop="address" show-overflow-tooltip label="公司地址"></el-table-column>
        <el-table-column sortable="custom" prop="phone" show-overflow-tooltip label="客服电话"></el-table-column>
        <el-table-column sortable="custom" prop="qq" show-overflow-tooltip label="客服QQ"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="OA.includes('jcxx:gywm:load')" size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button v-if="OA.includes('jcxx:gywm:editor')" size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="编辑"></el-button>
            <el-button v-if="OA.includes('jcxx:gywm:del')" size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑关于我们' : '添加关于我们'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="官网：" prop="website">
          <el-input v-model="form.website" clearable></el-input>
        </el-form-item>
        <el-form-item label="运营合作：" prop="cooperation">
          <el-input v-model="form.cooperation" clearable></el-input>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="form.email" clearable></el-input>
        </el-form-item>
        <el-form-item label="公司地址：" prop="address">
          <el-input v-model="form.address" clearable :row="3"></el-input>
        </el-form-item>
        <el-form-item label="客服电话：" prop="phone">
          <el-input v-model="form.phone" clearable  :maxlength="11" :row="3"></el-input>
        </el-form-item>
        <el-form-item label="客服QQ：" prop="qq">
          <el-input v-model="form.qq" clearable :row="3"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="关于我们详情" :visible.sync="bVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="官网" prop="website">
          <span v-text="details.website"></span>
        </el-form-item>
        <el-form-item label="运营合作：" prop="cooperation">
          <span v-text="details.cooperation"></span>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <span v-text="details.email"></span>
        </el-form-item>
        <el-form-item label="公司地址：" prop="address">
          <span v-text="details.address"></span>
        </el-form-item>
        <el-form-item label="客服电话：" prop="phone">
          <span v-text="details.phone"></span>
        </el-form-item>
        <el-form-item label="客服QQ：" prop="qq">
          <span v-text="details.qq"></span>
        </el-form-item>
        <el-form-item label="客服电话：" prop="phone">
          <span v-text="details.phone"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'aboutUs',
  components: {
    PagedTable
  },
  mixins: [pageMixin],
  data() {
    return {
      queryParams: {
        aboutName: null,
        aboutManager: null,
      },

      form:{
        id:'',
        website: '',
        cooperation:'',
        email:'',
        address:'',
        phone:'',
        qq:'',
      },
      details:{
        website: '',
        cooperation:'',
        email:'',
        address:'',
        phone:'',
        qq:'',
      },
      formRules:{
        website: [{ required: true, message: '请输入官网网址', trigger: 'blur' }],
        cooperation: [{ required: true, message: '请输入合作人联系方式', trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        address: [{ required: true, message: '请输入公司地址', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        qq: [{ required: true, message: '请输入QQ号', trigger: 'blur' }],
      },

      pageApi:'aboutUsList',
      insertApi:'aboutUsAdd',
      editorApi:'aboutUsEdit',
      deleteApi:'aboutUsDelete',
      loadApi:'aboutUsLoad',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemAbout", ["aboutList", "aboutTotal"]),
  },
  methods: {
    ...mapActions("systemAbout",["aboutUsList", "aboutUsAdd", "aboutUsEdit", "aboutUsDelete", "aboutUsLoad"]),
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>
